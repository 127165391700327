import * as RealmWeb from "realm-web"

import React, { useContext, useEffect, useState } from "react"


const RealmAppContext = React.createContext(null)

const RealmApp = ({ children }) => {
    const [app, setApp] = useState(new RealmWeb.App({id: process.env.REACT_APP_REALM_APP_ID}))
    const [user, setUser] = useState(null)

    const logIn = async () => {
        if(!user) {
            console.log("user logged in")
        setUser(app.currentUser ? app.currentUser : await app.logIn(RealmWeb.Credentials.anonymous()))
        }
    }

    const logOut = () => {
        if (user !== null) {
            app.currentUser.logOut()
            setUser(null)
        }
    }
    useEffect(() => {
        logIn()
      }, [user])

    return (
        <RealmAppContext.Provider
            value={{
                logIn,
                logOut,
                user,
            }}
        >
            {children}
        </RealmAppContext.Provider>
    )
}

const useRealmApp = () => {
    const realmContext = useContext(RealmAppContext)
    return realmContext
}

export {RealmApp, useRealmApp}