function DesktopHome () {
    console.log("DesktopHome")
    return (
        <div>
            <div>
                Work in progress
            </div>
        </div>
    );
}

export default DesktopHome;