import logo from './logo.svg';
import './App.css';
import * as Realm from "realm-web";
import { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import {ViewportProvider} from './ViewportContext'
import Home from './pages/Home/Home';
import { RealmApp } from './RealmAppContext';
import { MongoDB } from './newMongoContext';

function App() {

  return (
    <Router>
      <RealmApp>
        <MongoDB>
          <ViewportProvider>
            <Routes>
              <Route exact path="/" Component={Home} />
            </Routes>
          </ViewportProvider>
        </MongoDB>
      </RealmApp>
    </Router>
  );
}

export default App;
