import { Col, Container } from "react-bootstrap"
import "./JourneyTable.css"

function JourneyCard () {
    return (
        <Container className="p-0">
            <Container className="square rounded-bottom" style={{width: "95%", backgroundColor: "white", padding: 10, display: "flex", flex: 1, flexDirection: "column"}}>
                <div className="table-row">
                    <div className="center" style={{width: "fit-content", padding: 8, flex: 4, borderBottom: "1px dashed black", borderRight: "1px dashed black", fontWeight:"bold", fontSize:18}}>Tuyến đi</div>
                    <div className="center" style={{width: "fit-content", padding: 8, flex: 2, borderBottom: "1px dashed black", fontWeight:"bold", fontSize:18}}>Giá Vé</div>
                </div>
                <TableRow journey="Hà Nội - Hải Phòng" price="220K-250K"/>
                <TableRow journey="Hà Nội - Hạ Long" price="260K-280K"/>
                <TableRow journey="Hải Phòng - Hạ Long" price="160K-180K"/>
                <TableRow journey="Hải Phòng - Nội Bài" price="450K-480K"/>
                <TableRow journey="Hạ Long - Nội Bài" price="490K-510K"/>
            </Container>
        </Container>
    )
}

function TableRow ({journey, price}) {
    return (
        <div className="table-row">
            <div style={{display: "flex", flex: 4, padding: 8, justifyContent:"center", borderRight: "1px dashed black"}}>
                <div className="square bg-primary rounded-pill" style={{width: "fit-content"}}>
                    <div className="center white-text" style={{color: "white", paddingInline:20}}>{journey}</div>
                </div>
            </div>
            <div className="center" style={{width: "fit-content", padding: 8, flex: 2}}>{price}</div>
        </div>
    )
}

export default JourneyCard