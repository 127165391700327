import React, { useContext, useEffect, useState } from "react"
import { useRealmApp } from "./RealmAppContext";

const MongoContext = React.createContext({});

const MongoDB = ({ children }) => {
  const { user } = useRealmApp()
  const [db, setDb] = useState(null)

  useEffect(() => {
    if (user !== null) {
        const client = user.mongoClient("mongodb-atlas")
        setDb(client.db("TransportBooker"))
        console.log("db is set")
    }
  }, [user])

  return (
    <MongoContext.Provider
        value={{
            db,
        }}
    >
        {children}
    </MongoContext.Provider>
)
};

const useMongoDB = () => {
  const mongoDBContext = useContext(MongoContext)
  return mongoDBContext
}

export {MongoDB, useMongoDB}