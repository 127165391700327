import { useEffect, useState } from "react"
import { useViewport } from "../../ViewportContext"
import { useMongoDB } from "../../newMongoContext";
import MobileHome from "./MobileHome"
import DesktopHome from "./DesktopHome";

function Home () {
    const {db} = useMongoDB()
    const [journeys, setJourneys] = useState([])
    const {width, height} = useViewport();

    useEffect(() => {
        const fetchData = async () => {
            if(db) {
                const results = db.collection("Journey")
                setJourneys(await results.find())
                console.log("journey fetched")
                console.log(journeys)
                journeys.map((journey) => console.log(journey.start))
            }
        }
        fetchData()
      }, [db])
    
    return width < 500 ? <MobileHome/> : <DesktopHome/>
}

export default Home;