import { Col, Container, Image, Row } from "react-bootstrap";
import "./MobileHome.css"
import JourneyTable from "../../component/JourneyTable/JourneyTable";
function MobileHome () {
    console.log("MOBILEHOME")
    return (
        <Container className="p-0 screen">
            <Container className="p-0">
                <Image width={window.innerWidth} src="Home.jpg"></Image>
            </Container>
            <Container className="p-0">
                <div className="center big-text white-text">Limosine (4h-21h hàng ngày)</div>
                <JourneyTable/>
                <div className="center very-big-text white-text">Hotline: 0788.22.89.88</div>
            </Container>
        </Container>
    );
}
export default MobileHome;